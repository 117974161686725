<template>
  <v-app>
    <div class="link-container">
      <router-view />
    </div>
  </v-app>
</template>
<script>
export default {
  name: 'PageLayout',
}
</script>
<style lang="css">
html,body{
  height: 100%;
  width: 100%;
}
.link-container{
  width:100%;
  height:100%;
  overflow: auto;
}
/* 스크롤바 설정*/
::-webkit-scrollbar {
  width: 6px;
}

/* 스크롤바 막대 설정*/
::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: #dcdfe6;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.v-application ,v-application--wrap{
  width: 100%;
  height:100%;
}

</style>
